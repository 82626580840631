.submitButton {
  padding-top: 15px;
}

.settingsDrawer {
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editManifestButton {
  margin-top: 15px;
  width: 100%;
}

.editManifestButtonPlaceHolder {
  height: 51px;
}

.submitButtonText {
  display: flex;
  align-items: center;
  gap: 5px;
}

.autoUpdateParams {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.settingsForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
