.iconWrapper {
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
}

.menuItemWrapper button {
  border: none;
  background-color: var(--zds-colors-neutral-100);
}

.buttonContent {
  display: none;
}
